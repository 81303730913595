<template>
  <div>
    <list />
  </div>
</template>
<script>
import List from './list/Index.vue'
export default {
  components: {
    List,
  },
}
</script>

